.formbg{
    background-color: #e1e1e1;
    width: 100%;
    padding: 24px;
}

.form-section{
    display: flex;
    justify-content: space-evenly;
}

h4{
    text-align: center;
    font-weight: 600;
    font-size: xx-large;
}

.form-img-section{
    width: 774px;
    height: 443px;
}

.form-img-section img{
    width: 100%;
    height: 100%;
}

@media (max-width:760px){
    .form-section{
        flex-direction: column;
    }
    .form-img-section {
        width: 376px;
        height: 443px;
    }
    .formbg{
        padding: 4px;
    }
}