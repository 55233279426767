.service-section{
    margin: 0px;
    margin-top: 70px;
    padding: 0px;
    height: 800px;
    background-image: url(https://redbridgeimmigration.com/wp-content/uploads/2020/06/22411-Converted-1.png?id=4080) !important;    
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    
   
}

.service-heading{
    position: relative;
    font-size: 40px;
    font-family: 'Poppins', sans-serif;
    color: #142b51;
    line-height: 45px;
    font-weight: 700;
    text-align: center;
    text-transform: capitalize;
    
}

.empty-space{
    height: 60px;
}

h4{
    color: #142b51;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
}

.services-list{
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin-top: 40px;
}

.row1{
    display: flex;
    flex-direction: row;
}

.row2{
    display: flex;
    flex-direction: row;

}
.sub-entry{
    display: flex;
    box-sizing: border-box;
}
.sub-entry-col{
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    margin-bottom: 50px !important;
    padding-top: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

}

.img-icon{
    float: left;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    text-align: center;
}

p {
    margin: 0 0 20px;
}

@media (max-width:600px) {
    .row1{
        display:flex;
        flex-direction: column;

    }
    .row2{
        
        flex-direction: column;
    }
    
.services-list{
    display: flex;
    flex-direction: column;
    gap: 0px;
    margin-top: 40px;
}
.service-section{
    margin: 0px;
    margin-top: 70px;
    padding: 10px;
    height: 1600px;
    background-image: url(https://redbridgeimmigration.com/wp-content/uploads/2020/06/22411-Converted-1.png?id=4080) !important;    
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    
   
}

   
}








