.supervisa{
    display: flex;
    box-sizing: border-box;
    float: left;
    margin-left: 2%;
    margin-right: 2%;
    flex-direction: column;
    gap: 50px;
}

.supervisa-first-image{
    width: 100%;
    height: 400px;
    
}

.supervisa-first-img{
    width: 100%;
    height: 100%;
    opacity: 0.8;
    
}
.supervisa-heading{
    position: absolute;
    font-size: 46px;
    font-weight: 600;
    margin-left: 16%;
    margin-top: 43%;
    color: white;
    z-index: 1;
}