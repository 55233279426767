.spousal-work-permit{
    display: flex;
    box-sizing: border-box;
    float: left;
    margin-left: 2%;
    margin-right: 2%;
    flex-direction: column;
    gap: 50px;
}

.spousal-work-permit-first-image{
    width: 100%;
    height: 400px;
}


.spousal-work-permit-first-img{
    width: 100%;
    height: 100%;
    opacity: 0.8;
    
}

.spousal-work-permit-heading{
    position: absolute;
    font-size: 46px;
    font-weight: 600;
    margin-left: 16%;
    margin-top: 43%;
    color: white;
    z-index: 1;
}

@media (min-width:768px){
    .spousal-work-permit-heading {
        position: absolute;
        font-size: 46px;
        font-weight: 600;
        margin-left: 23%;
        margin-top: 4%;
        color: white;
        z-index: 1;
    }
    .spousal-work-permit-first-image {
        width: 100%;
        height: 578px;
    }
}