.cards1{
    align-items: center;
    display: flex;
    flex-direction: column;

}
.h2{
    text-align: center;
    margin: auto;
}
.heading{
    font-size: 40px;
    text-align: center;
    margin: 20px auto;
}

.card-elements{
    margin-top: 70px;
    display: flex;
    justify-content: space-between;
}

@media (max-width:400px){
    .card-elements{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 40px;
    }
}

