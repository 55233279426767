.appointment-card{

    display: block;
    position: relative;
    padding: 30px;
    border: 1px solid #ececec;
    box-shadow: 0 0 10px #ececec;
    overflow: hidden;
    width: 647px;
    margin: auto;
}

.appointment-card-heading{
    padding-bottom: 20px;
    margin-bottom: 40px;
    margin-top: 0;
    border-bottom: 2px solid #ececec;
    font-size: 24px;
    line-height: 34px;
    font-weight: 600;
    font-family: Verdana, Geneva, Tahoma, sans-serif;

}

.appointment-card-intro{
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
    margin: 0 0 15px;
    font-weight: 500;
}

.appointment-card-details{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 13px;
    font-weight: 500;
    color: #2d2d2d;
}

.appointment-card-detail{
   display: flex;
   gap: 10px;
   align-items: baseline;
}

.react-calendar{
    margin: auto;
}

@media (max-width:768px){
    .appointment-card{

        display: block;
        position: relative;
        padding: 30px;
        border: 1px solid #ececec;
        box-shadow: 0 0 10px #ececec;
        overflow: hidden;
        width: auto;
       
    }
    .appointment-card-details{
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 13px;
        margin-bottom: 20px;
        font-size: 13px;
        font-weight: 500;
        color: #2d2d2d;
        margin-left: 0;
    
    }
    
}