.specific-work-permit{
    display: flex;
    box-sizing: border-box;
    float: left;
    margin-left: 2%;
    margin-right: 2%;
    flex-direction: column;
    gap: 50px;
}

.specific-work-permit-first-image{
    width: 100%;
    height: 400px;
}


.specific-work-permit-first-img{
    width: 100%;
    height: 100%;
    opacity: 1;
    
}

.specific-work-permit-heading{
    position: absolute;
    font-size: 40px;
    font-weight: 600;
    margin-top: 18%;
    text-align: center;
    color: #31708f;
    z-index: 1;
}


.specific-work-permit-note{
    background-color: #d9edf7;
    border-radius: 10px;
    padding: 5px;
    margin-top: 14px;
    color: #31708f;
}

@media (min-width:768px){
    .specific-work-permit-heading{
        position: absolute;
    font-size: 40px;
    font-weight: 600;
    margin-top: 10%;
    color: #31708f;
    z-index: 1;
    margin-left: 34%;
    }
    .specific-work-permit-first-image {
        width: 100%;
        height: 552px;
    }
}