.contact{
    margin-top: 0%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 70px;
}

.contact-first-section-heading{
    font-size: 48px;
    color: #2a2d2e;
    line-height: 60px;
    font-family: Montserrat;
    text-align: center;
    font-weight: 700;
    font-style: normal;    
    margin-top: 183px;
}

@media (max-width:768px){
    .contact{
        margin-top: 20%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 70px;
    }
}
