.success-stories-section{
    margin-top: 10%;
    display: flex;
    gap: 2px;
    flex-direction: column;

}

.success-header{
    font-size: 30px;
    font-weight: 700;
    color: #142b51;
}