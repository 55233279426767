.footer{
    width: 100%;
    left: 0%;
    right: 0%;
    margin: 0;
    height: 350px;
    margin-bottom: 0px;
    color: black;
    background-color: #005792;
    background-color: #fff;
   background-image: url("/src/images/wave.svg");
    background-size: cover;
    

    padding: 20px;
}

.footer-row{
    display: flex;
    color: #fff;
    gap: 170px;
}


.info1{
    margin-right: 10px;
    margin-top: 240px;
    text-align: center;
    
}

.info2{
    margin-top: 190px;
}
.info3{
   margin-top: 120px;
}

.info4{
    margin-top: 120px;
    
}

@media(max-width:768px){
    .footer{
        height: auto;
        background-color: #005792;
        background-image: none;
        margin: 0;
        padding: 40px;
    }
    .footer-row{
        display: flex;
        flex-direction: column;
        gap: 3px;
    }
    
.info2{
    margin-top: 0px;
}

.info1{
    margin-top: 0px;
    text-align: left;
}

.info3{
   margin-top: 0px;
}

.info4{
    margin-top: 0px;
    
}
}
