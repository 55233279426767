.social{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 40px;
    position: relative;
    margin-top: 160px;
}

.social-header{
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    color: #142b51;
}

.social-icons{
    display: flex;
    justify-content: center;
    gap: 54px;
}

.image-a{
    width: 5%;
}


.image-a:hover{
    all: initial;
    width: 4%;
}

.social-image{
    height: 60px;
}


.social-image:hover{
    cursor: pointer;
}

@media (max-width:400px){
    .social-header{
        margin-left: 10%;
        margin-right: 10%;
    }
        
}