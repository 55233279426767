.StudyPermit{
    display: flex;
    box-sizing: border-box;
    float: left;
    margin-top: 30%;
    margin-left: 2%;
    margin-right: 2%;
    flex-direction: column;
    gap: 50px;
}

.study-permit-image{
    width: 100%;
    height: 400px;
}

.study-permit-img{
    width: 100%;
    height: 100%;
    opacity: 0.3;
    z-index: 1;
}

.study-permit-heading{
z-index: 1;
position: absolute;
color: black;
font-size: 40px;
font-weight: 600;
left: 20%;
top: 50%;

}

ol{
    background-color: #005792;;
}

.study-permit-note{
    background-color: #d9edf7;
    border-radius: 10px;
    padding: 5px;
    margin-top: 14px;
    color: #31708f;
}

.study-permit-end-image{
    width: 100%;
    height: 500px;
}

.study-permit-end-img{
    width: 100%;
    height: 100%;
    z-index: 1;
}

.study-permit-end-image-text{
    z-index: 2;
    position: absolute;
    margin-top: 30px;
    color: white;
    font-size: 40px;
    font-weight: 500;
    text-align: center;
}


@media (min-width:768px){
    .StudyPermit {
        display: flex;
        box-sizing: border-box;
        float: left;
        margin-top: 0%;
        margin-left: 2%;
        margin-right: 2%;
        gap: 50px;
    }

    .study-permit-heading {
        z-index: 1;
        position: absolute;
        color: #005792;
        font-size: 70px;
        font-weight: 600;
        left: 34%;
        top: 30%;
    }
    .study-permit-end-image-text{
        z-index: 2;
        position: absolute;
        margin-top: 30px;
        color: white;
        font-size: 40px;
        font-weight: 500;
        margin-left: 30%;
    }
}