.spousal-sponsorship{
    display: flex;
    box-sizing: border-box;
    float: left;
    margin-left: 2%;
    margin-right: 2%;
    flex-direction: column;
    gap: 50px;
}

.spousal-sponsorship-first-image{
    width: 100%;
    height: 400px;
}

.spousal-sponsorship-heading{
    position: absolute;
    font-size: 46px;
    font-weight: 600;
    text-align: center;
    margin-top: 43%;
    color: white;
    z-index: 1;
}

.spousal-sponsorship-first-img{
    width: 100%;
    height: 100%;
    opacity: 0.8;
}

@media (min-width:768px){
    .spousal-sponsorship-heading {
        position: absolute;
        font-size: 60px;
        font-weight: 600;
        text-align: center;
        margin-top: 10%;
        margin-left: 34%;
        color: white;
        z-index: 1;
    }
    .spousal-sponsorship-first-image{
        width: 100%;
        height: 500px;
    opacity: 0.5;
    }
}