.info{
    background-color: ghostwhite;
    padding: 20px;
    position: relative;
    display: flex;
    justify-content: space-between;
    
    
}

.h4{
    margin: 10px;
}

.image-box img{
    height: 407px;
    width: 300px;
}


.info-list{
    background-color: none;
}

.categories{
    display: flex;
    flex-direction: column;
}

@media (max-width:768px){
    .info{
        display: flex;
        flex-direction: column;
        gap: 40px;
        align-items: center;
    }
}