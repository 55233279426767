.faq{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0px;
    margin-right: 0px;
    text-align: center;
    margin-top: 0%;
}

.faq-heading{
    font-family: 'Montserrat', sans-serif;
    font-size: 40px;
    font-weight: 700;
    color: #1a1818;
    
}

.faq-sub-heading{
    font-size: 16px;
}

.faq-content{
    display: flex;
    word-break: break-word;
    margin-top: 4%;
    margin-bottom: 4%;
    gap: 20px;
}

.faq-detail{
background-color: #e9e9e9;
color: #666;
font-size: 1em;
padding: 7px;
border-radius: 6px;
}

.faq-detail-content{
padding: 10px;
}

.faq-detail-summary{
    margin-left: 20px;
    margin-bottom: 17px;
    margin-top: 17px;
}

.faq-ul{
background-color: #005792;
text-align: left;
padding: 10px;
}

.faq-links{
color: #005792;
}


.faq-content-first-section{
    width: 2914px;
}

@media (max-width:768px){
    
.faq-content{
    display: flex;
    flex-direction: column;

}

.faq-content-first-section{
    width: auto;
}


.faq-content{
    display: flex;
    word-break: break-word;
    margin-top: 4%;
    margin-bottom: 20%;
    gap: 20px;
}

.faq{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0px;
    margin-right: 0px;
    text-align: center;
    margin-top: 10%;
}

.faq-heading{
    font-family: 'Montserrat', sans-serif;
    font-size: 29px;
    font-weight: 700;
    color: #1a1818;
    
}
}