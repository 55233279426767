.first-gallery{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
}

.success-stories-gallery{
  margin-top: 5%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 60px;
}
.first-gallery-image-wrap{
  width: 10%;
}

.first-gallery{
  display: grid;
  grid-template-columns: repeat(5,1fr);
  row-gap: 10px;
  margin: auto;
}

.first-gallery-img{
  width: 200px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.first-gallery-text{
  
    font-size: 30px;
    font-weight: 700;
    color: #142b51;;
    text-align: center;

}


@media (max-width:768px){
  
.first-gallery{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  row-gap: 10px;
  margin: auto;
}


  .first-gallery-img {
    width: 100px;
}

}