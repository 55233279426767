.services-card{
    display: block;
    position: relative;
    padding: 30px;
    border: 1px solid #ececec;
    box-shadow: 0 0 10px #ececec;
    overflow: hidden;
    margin-bottom: 50px;
}



.services-card-info{
display: flex;
flex-direction: column;
margin-top: 40px;
gap: 10px;
}

.services-card-heading{
    padding-bottom: 20px;
    margin-bottom: 40px;
    margin-top: 0;
    border-bottom: 2px solid #ececec;
    font-size: 24px;
    line-height: 34px;
    font-weight: 600;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    
}
@media (min-width:768px){
    .services-card{
        display: flex;
        justify-content: space-between;
    }

    
}

@media (max-width:768px){
    .service-card-image{
        display: none;
    }
}