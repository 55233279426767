.slider-container{
    position: relative;
    width: 1400px;
    margin-top: 6%;
    height: 700px;
    background-color: #f5f5f5;
    box-shadow: 0 30px 50px #a9a8a8;
}

.item {
    display: inline-block;
    transition: 0.8s;
    border-radius: 20px;
    box-shadow: 0 30px 50px #505050;
}

.item-image{
    width: 100%;
    height: 100%;
}
.slider-card{
   position: absolute;
   display: flex;
   flex-direction: row;
   gap: 14px;
   z-index: 2;
   top: 200px;
   right: -100px;
}

.slider-card-image{
    width: 200px;
    height: 300px;
    background-color: red;
    border-radius: 20px;
}

@media (max-width:768px){
    .slider-container{
        position: relative;
        width: 360px;
        margin-top: 6%;
        height: 300px;
        background-color: #f5f5f5;
        box-shadow: 0 30px 50px #a9a8a8;
    }
    
.item {
    height: 100%;
    width: 100%;
    transition: 0.8s;
    border-radius: 20px;
    box-shadow: 0 30px 50px #505050;
}

.item-image{
    height: 100%;
    width: 100%;
}
.slider-card-image{
    width: 100px;
    height: 100px;
    background-color: red;
    border-radius: 20px;
}


.slider-card{
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 14px;
    z-index: 2;
    top: 250px;
    right: 23px;
 }
}