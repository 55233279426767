.LMIA{
    display: flex;
    box-sizing: border-box;
    float: left;
    margin-left: 2%;
    margin-right: 2%;
    flex-direction: column;
    gap: 50px;
}

.LMIA-first-image{
    width: 100%;
    height: 400px;
}

.LMIA-first-img{
    width: 100%;
    height: 100%;
    opacity: 1;
}