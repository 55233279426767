.navbar{
	background-color: #005792;
	width: 100%;
	height: 80px;
  }
  
  .navbar-elements{
	width: 100%;
	margin: 0px 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
  }
  
  .logo{
	height: 100px;
	width: 150px;
	margin-left: 10px;
	margin-top: 2px;
	z-index: 3;
  }

  #box{
	display: none;
  }

  #box1{
	display: none;
  }

  #box2{
	display: none;
  }

  #box3{
	display: none;
  }

  #box4{
	display: none;
  }

  #box5{
	display: none;
  }
  
  .menu ul{
	display: flex;
	justify-content: center;
	gap: 30px;
	flex-direction: row;
	padding: 0px;
    gap: 38px;
    align-items: center;
  }
  
  li{
	list-style: none;
	color: white;
	text-decoration: none;
	
}

.menu li:hover,
.menu li:hover a {
  background-color: white;
  width: 100px;
  padding: 2px;
  border-radius: 10px;
  color: #005792;
  max-width: 200px;
  text-align: center;
  text-decoration: none;
} 
  a{
	text-decoration: none;
	color: white;
	width: 100%;
	font-weight: 600;
    font-size: 16px;
  }
   
  
  h1{
	color: white;
	text-align: center;
  }
  
  .toggle-button{
	display: none;
  
  }

  #dropdown-basic-button:hover {
	color: #005792 !important;
  }
  
  #dropdown-basic-button {
	box-shadow: none;
	background-color: transparent;
	border: none;
	font-size: 16px;
	font-weight: 600;
	color: white;
  }

  .dropdown-item {
	background-color: white !important;
	color: black !important;
	text-align: left !important;
	padding: 10px !important;
  }


  .dropdown-menu{
	width: 200px;
	color: #005792 !important;
  }
	
  @media (max-width: 768px){
	.toggle-button{
		display: flex;
		flex-direction: column;
		gap: 8px;
		width: 30px;
		height: 30px;
		cursor: pointer;
		margin-bottom: 10%;
		margin-right: 2%;
	}
	.toggle-button .toggle-bar{
		width: 30px;
		height: 16px;
		color: white;
		background-color: white;
		border-radius: 10px;
	}   
	.menu{
	display: none;
	}

	.navbar.open{
		
			height: 200px;
		  
	}
  
	.menu ul{
		width: 100%;
		flex-direction: column;
		top: 20%;
		transition: all 0.5s ease-in-out;
	transform-origin: top left;
	transform: scaleY(1);
	
	}
  
	.menu li:hover{
		background: #53CDE2;
		color: #005792;
		width: 100%;
	}
  
	.menu.active{
	  position: absolute;
	  display: flex;
	  justify-content: center;
	  text-align: center;
	  top: 100%;
	  left: 0%;
	  right: 0%;
	 
	  background-color: #005792;
	  z-index: 2;
	  
  }
  
	a:hover{
		background-color: #53CDE2;
		color: white;
	}

	.navbar.open #box {
		height: 40px;
        width: 40px;
		position: absolute;
		display: block;
		border: 2px solid gainsboro;
		animation-name: stretch;
		z-index: 4;
		animation-duration: 3s; 
		animation-timing-function: ease-out; 
		animation-delay: 0;
		animation-direction: alternate;
		animation-iteration-count: infinite;
		animation-fill-mode: none;
		animation-play-state: running;
		left: 10px;
		top: 180px;
		opacity: 0.2;
	}

	.navbar.open #box1{
		height: 40px;
        width: 40px;
		position: absolute;
		display: block;
		border: 2px solid gainsboro;
		animation-name: stretch;
		z-index: 4;
		animation-duration: 4s; 
		animation-timing-function: ease-out; 
		animation-delay: 0;
		animation-direction: alternate;
		animation-iteration-count: infinite;
		animation-fill-mode: none;
		animation-play-state: running;
		left: 300px;
		top: 200px;
		opacity: 0.2;
	}
	
	.navbar.open #box2{
		height: 30px;
        width: 30px;
		position: absolute;
		display: block;
		border: 2px solid gainsboro;
		animation-name: stretch;
		z-index: 4;
		animation-duration: 3s; 
		animation-timing-function: ease-out; 
		animation-delay: 0;
		animation-direction: alternate;
		animation-iteration-count: infinite;
		animation-fill-mode: none;
		animation-play-state: running;
		left: 250px;
		top: 250px;
		opacity: 0.2;
	}
	
	.navbar.open #box3{
		height: 40px;
        width: 40px;
		position: absolute;
		display: block;
		border: 2px solid gainsboro;
		animation-name: stretch;
		z-index: 4;
		animation-duration: 7s; 
		animation-timing-function: ease-out; 
		animation-delay: 0;
		animation-direction: alternate;
		animation-iteration-count: infinite;
		animation-fill-mode: none;
		animation-play-state: running;
		left: 90px;
		top: 300px;
		opacity: 0.3;
	}
	
	.navbar.open #box4{
		height: 40px;
        width: 40px;
		position: absolute;
		display: block;
		border: 2px solid gainsboro;
		animation-name: stretch;
		z-index: 4;
		animation-duration: 4s; 
		animation-timing-function: ease-out; 
		animation-delay: 0;
		animation-direction: alternate;
		animation-iteration-count: infinite;
		animation-fill-mode: none;
		animation-play-state: running;
		left: 100px;
		top: 210px;
		opacity: 0.2;
	}
	.navbar.open #box5{
		height: 20px;
        width: 20px;
		position: absolute;
		display: block;
		border: 2px solid gainsboro;
		animation-name: stretch;
		z-index: 4;
		animation-duration: 4s; 
		animation-timing-function: ease-out; 
		animation-delay: 0;
		animation-direction: alternate;
		animation-iteration-count: infinite;
		animation-fill-mode: none;
		animation-play-state: running;
		left: 40px;
		top: 260px;
		opacity: 0.2;
	}
	
	.navbar.open #box6{
		height: 20px;
        width: 20px;
		position: absolute;
		display: block;
		border: 2px solid gainsboro;
		animation-name: stretch;
		z-index: 4;
		animation-duration: 4s; 
		animation-timing-function: ease-out; 
		animation-delay: 0;
		animation-direction: alternate;
		animation-iteration-count: infinite;
		animation-fill-mode: none;
		animation-play-state: running;
		left: 200px;
		top: 300px;
		opacity: 0.2;
	}
	
	.navbar.open #box7{
		height: 20px;
        width: 20px;
		position: absolute;
		display: block;
		border: 2px solid gainsboro;
		animation-name: stretch;
		z-index: 4;
		animation-duration: 4s; 
		animation-timing-function: ease-out; 
		animation-delay: 0;
		animation-direction: alternate;
		animation-iteration-count: infinite;
		animation-fill-mode: none;
		animation-play-state: running;
		left: 180px;
		top: 180px;
		opacity: 0.2;
	}

	.navbar.open .logo{
		margin-top: -15px;
	}

	.navbar.open .toggle-button{
		margin-bottom: 15%;
	}
	

	
@keyframes stretch {
	0% {
	  transform: scale(.3);
	  border: 2px solid gainsboro;
	  background-color: blue;
	  
	}
	50% {
		border: 2px solid gainsboro;
	  rotate: 90deg;
	}
	100% {
		border: 2px solid gainsboro;
	 
	}
  }

	
  }
