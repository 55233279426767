.cont{  
    left: 0%;
    right: 0%;
    margin: 0;
    width: 100%;
  
}
.images{
   
    padding: 10px;
   margin: 2px;
  
}

.images .image1{
    text-align: center;
}