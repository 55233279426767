.gsr-about-section{
    display: flex;
    flex-direction: column;
    word-break: break-word;
}

.gsr-about{
    position: relative;
    box-sizing: border-box;
    display: flex;
    transition: opacity .5s ease;
    overflow: hidden;
    margin-left: -15px;
    margin-right: -15px;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 127px;
    width: 100%;

}

strong{
    font-size: 14px;
    font-weight: 700;
}

.gsr-image{
   -webkit-box-pack: center;
   z-index: 1;
   box-sizing: border-box;
   width: 860px;
   
}

.gsr-div-image{
    width: 852px;
    
}

.written-section{
    display: flex;
    flex-direction: column;
    height: 1638px;
    gap: 20px;
    word-break: break-word;
}
.about-section-header{
    font-weight: 700;
    line-height: 60px;
    font-size: 30px;
    font-style: normal;
}
.about-section-closing-comments{
    font-size: 14px !important;
    display: flex;
    flex-direction: column;
}


.faq {
    padding: 1.5rem;
    
    details {
        
      border: 1px solid #ddd;
      background: #fff;
      margin-bottom: 1.5rem;
      border-radius: 0.35rem;
      box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
          0 10px 10px -5px rgba(0, 0, 0, 0.04);
      
      summary {
        cursor: pointer;
        padding: 1rem;
        border-bottom: 1px solid #ddd;
      }
      div {
        padding: 1rem 1.5rem;
      }
    }
  }

 

  .about-section-contact-form{
background-color: rgba(0, 0, 0, 0);
background-image: linear-gradient(rgb(0,171,201),rgb(20,43,81));
background-origin: padding-box;
background-clip: border-box;
width: 454px;
    border-radius: 10px;
    height: 593px;
  }

  .about-section-contact-form-wrap{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-sizing: border-box;
    color: rgb(102, 102, 102);
    display: block;
    font-family:sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
    text-size-adjust: 100%;
    -webkit-box-direction: normal;
    transform: rotate(-2deg);
  }

  .about-section-contact-form-content{
    background-attachment: scroll;
background-clip: border-box;
background-color: rgb(255, 255, 255);
background-image: none;
background-origin: padding-box;
background-position-x: 0%;
background-position-y:  0%;
background-repeat-x:  repeat;
background-repeat-y:  repeat;
background-size:  auto;
border-bottom-left-radius:  10px;
border-bottom-right-radius:  10px;
border-top-left-radius : 10px;
border-top-right-radius:  10px;
box-sizing:  border-box;
color:  rgb(102, 102, 102);
display : block;
font-family:  "Open Sans", sans-serif;
font-size : 14px;
font-weight : 400;
height : 573.737px;
line-height : 25px;
padding-bottom : 60px;
padding-left : 30px;
padding-right : 30px;
padding-top : 60px;
transform : skewX(-3deg);
width:  445px;
-webkit-box-direction: normal;
-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .about-section-faq-form{
    box-sizing: border-box;
color: rgb(102, 102, 102);
display: flex;
gap: 40px;
font-family : "Open Sans", sans-serif;
font-size: 14px;
font-weight:  400;
height: auto;
line-height:  25px;
margin: 100px auto;
text-size-adjust:  100%;
width: 1092px;
-webkit-tap-highlight-color:  rgba(0, 0, 0, 0);
  }

  .mb-3-about-row{
    rotate: 1.2deg;
    margin-top: 40px;
  }

  .submit-contact-form{
    rotate: 1.2deg;
    margin-left: 30%;
    margin-top: 14%;
  }

  .about-section-form-heading{
   font-size: 17px;
  }

  .about-section-form-header{
    margin: auto;
    margin-left: 15%;
    font-size: 33px;
    font-weight: 700;
  }

  .about-section-faq-heading{
    color: rgb(49,49,49);
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
    text-align: left;
    text-size-adjust: 100%;

  }

  summary{
    box-sizing: border-box;
    color: rgb(34, 34, 34);
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    line-height: 23.4px;
    text-align: left;
    text-size-adjust: 100%;
  }

  .summary-content{
    box-sizing: border-box;
    color: rgb(102, 102, 102);
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    text-size-adjust: 100%;
    margin-top: 10px;
  }

  details{
    margin-top: 40px;
  }

  .about-section-faq-links{
    color: blue;
  }

  @media (max-width:768px){
    .gsr-about{
        flex-direction: column;
    }

    .gsr-image{
       height: 400px;
        width: 400px;
        
     }
     
     .gsr-div-image{
         width: 100%;
         height: 100%;
     }

     pre{
    display: block;
    font-size: 87.5%;
    color: #212529;
    text-wrap: wrap;
    margin: 0;
     }
     .written-section{
        margin: 10%;
        height: auto;
            width: -webkit-fill-available;

     }

     strong{
        font-size: 17px;line-height: 4;
     }

     .gsr-about-section-two-image{
        width: 353px;
        height: 500px;
     }

     .about-section-faq-form{
        flex-direction: column;
        padding: 0px;
     }
     .about-section-contact-form-content{
        width: 357px;
     }
     .about-section-contact-form {
        width: 366px;
        margin-left: 3px;
     }

     .about-section-form-header{
        margin-left: 0;
     }

     .faq{
        margin-right: 50%;

     }
     .summary-content{
        margin-right: 0px;
        text-align: left;
     }
     .gsr-about-section-two-image{
width: auto;
height: auto;
     }
     .about-section-faq-form{
     
  width: auto;
    }
  }